var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, ref } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { Button } from '@/components';
import Swal from 'sweetalert2';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'SubHeader',
    components: {
        Button: Button
    },
    props: {
        isWatMonitor: {
            type: Boolean,
            default: false
        },
        isWatMonitorV3: {
            type: Boolean,
            default: false
        },
        isSharedReport: {
            type: Boolean,
            default: false
        },
        canDownloadExcel: {
            type: Boolean,
            default: false
        },
        excelInProgress: {
            type: Boolean,
            default: false
        }
    },
    computed: __assign(__assign({}, mapGetters([
        'clientCreditsPlan',
        'isAuthenticated',
        'test',
        'reportsList',
        'reportTab',
        'isSuperAdmin',
        'isObserver',
        'canCreateStudy',
        'hasAccessToLogs',
        'openedStudyTitle'
    ])), { breadCrumbs: function () {
            var breadCrumbs = [];
            var routerName = this.router.currentRoute.value.name ? this.router.currentRoute.value.name : '';
            if (routerName != 'dashboard')
                breadCrumbs.push({ name: '<i class="fas fa-home"></i>', link: 'dashboard' });
            if (routerName == 'transactions')
                breadCrumbs.push({ name: this.$t('suite_subheader_transactions') });
            if (routerName == 'settings')
                breadCrumbs.push({ name: this.$t('suite_subheader_profile') });
            if (routerName.includes('newstudy'))
                breadCrumbs.push({ name: this.$t('suite_subheader_new_study') });
            if (routerName == 'watfocusconfig')
                breadCrumbs.push({ name: this.openedStudyTitle });
            return breadCrumbs;
        }, showShareButton: function () {
            return this.test.testCommercialType != 'WAT_UX_QUANTI' && this.test.testCommercialType != 'WAT_UX';
        } }),
    methods: __assign(__assign({}, mapMutations({
        updateReportTab: 'updateReportTab'
    })), { goBack: function () {
            this.router.push({ name: 'dashboard' });
        }, isDashboardView: function () {
            return this.router.currentRoute.value.name == 'dashboard';
        }, showBreadCrumbs: function () {
            return this.isAuthenticated;
        }, showSuiteLinks: function () {
            return !this.isWatMonitor;
        }, openCreditsForm: function () {
            return __awaiter(this, void 0, void 0, function () {
                var vm;
                return __generator(this, function (_a) {
                    store.dispatch('createStatisticsClient', { concept: 'MORE_CREDITS_POPUP_OPEN' });
                    vm = this;
                    Swal.fire({
                        title: vm.$t('suite_swal_contact_title'),
                        input: 'textarea',
                        inputValue: vm.$t('suite_swal_contact_text'),
                        icon: 'info',
                        confirmButtonText: vm.$t('suite_swal_contact_action'),
                        showCloseButton: true,
                        inputValidator: function (value) {
                            if (!value)
                                return vm.$t('suite_swal_credits_error');
                            vm.sendContactForm(value, 'contactForCreditsPlan');
                            return null;
                        }
                    });
                    return [2 /*return*/];
                });
            });
        }, openContactForm: function () {
            var vm = this;
            Swal.fire({
                title: vm.$t('suite_swal_contact_title'),
                input: 'textarea',
                inputPlaceholder: vm.$t('suite_swal_credits_text'),
                icon: 'info',
                confirmButtonText: vm.$t('suite_swal_contact_action'),
                showCloseButton: true,
                inputValidator: function (value) {
                    if (!value)
                        return vm.$t('suite_swal_credits_error');
                    vm.sendContactForm(value, 'contactForDiy');
                    return null;
                }
            });
        }, generateExcelAggregated: function () {
            this.$emit('loaded-excel-aggregated');
            var vm = this;
            Swal.fire({
                title: vm.$t('suite_info_generating_excel_aggregated_title'),
                html: vm.$t('suite_info_generating_excel_aggregated_text'),
                icon: 'success'
            });
        }, sendContactForm: function (message, storeActionName) {
            return __awaiter(this, void 0, void 0, function () {
                var vm;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            vm = this;
                            return [4 /*yield*/, store.dispatch(storeActionName, { message: message })
                                    .then(function (data) {
                                    Swal.fire(vm.$t('suite_swal_contact_sent'), vm.$t('suite_swal_contact_sent_advert'), 'success');
                                })
                                    .catch(function (response) {
                                    Swal.fire('', vm.$t('suite_swal_contact_sent_error'), 'error');
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }, navigateTo: function (breadCrumb, index) {
            if (breadCrumb.link == 'dashboard')
                this.goBack();
        }, downloadExcel: function () {
            if (this.isWatMonitorV3)
                return;
            this.$emit('download-excel');
        } }),
    setup: function () {
        var MonitorReports = ref(null);
        var showReportList = ref(false);
        var router = useRouter();
        var route = useRoute();
        onClickOutside(MonitorReports, function (event) { return showReportList.value = false; });
        return { MonitorReports: MonitorReports, showReportList: showReportList, route: route, router: router };
    }
});
